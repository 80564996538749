<template>
  <div class="contact_warp">
    <div><span>联系电话:</span>4008291883/18902267233</div>
    <div><span>电子邮箱:</span>sunray365@sunray365.com</div>
    <div><span>交流QQ群:</span>529407933</div>
    <div><span>微信客服号:</span>shanlimingshi1/shanlimingshi3</div>
    <!-- <div><span>客服时间:</span>周一至周五：上午9:00-12:00 下午2:00-6:00</div> -->
    <div><span>注销账号:</span>请联系我们</div>
    <div><span>举报电话:</span>020-38329212</div>
    <div><span>举报邮箱:</span>shanlimingshi@126.com</div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.contact_warp {
  margin-left: 6.88vw;
  div {
    font-size: 24rem;
    font-weight: 500;
    color: #363636;
    margin-bottom: 20rem;
    display: flex;
    span {
      color: #999999;
      width: 150rem;
      text-align: right;
      margin-right: 15rem;
    }
  }
}
</style>